import { FC } from 'react';

import { motion } from 'framer-motion';

import { AboutProps } from '../types/About.types';

import 'styles/pages/About.styles.scss';

export const WorkExperience: FC<AboutProps> = ({ deviceType, lang }) => {
    return (
        <section className='workExperienceSection'>
            <div className='experienceTitleDiv'>
                <h1 className='experienceTitle'>
                    {lang.t('about.experience.title.1')}{' '}
                    <span className='gradientHeadTitle'>
                        {lang.t('about.experience.title.2')}
                    </span>
                </h1>
            </div>
            <div className='experienceContentContainer'>
                <div className='experienceItem'>
                    <div className='titleSectionExperience'>
                        <div className='yearExperienceContainer'>
                            <p className='yearExperience'>2024</p>
                        </div>
                        <div className='leftSectionExperience'>
                            <h3 className='titleExperience'>
                                {lang.t('about.experience.graphic')}
                            </h3>
                            <p className='functionExperience'>
                                {lang.t('about.experience.stage')}, Motionmill
                            </p>
                        </div>
                    </div>
                    <p className='textExperience'>
                        {lang.t('about.experience.motionmill.text.1')}
                        <br />
                        <br />
                        {lang.t('about.experience.motionmill.text.2')}
                    </p>
                </div>
                {deviceType == ('mobile' || 'ipad') ? (
                    <div className='underlineContainer'>
                        <div className='underlineProject'></div>
                    </div>
                ) : (
                    <div className='underlineSkillsContainer' />
                )}
                <div className='experienceItem'>
                    <div className='titleSectionExperience'>
                        <div className='yearExperienceContainer'>
                            <p className='yearExperience'>2023</p>
                        </div>
                        <div className='leftSectionExperience'>
                            <h3 className='titleExperience'>
                                {lang.t('about.experience.mobile')}
                            </h3>
                            <p className='functionExperience'>
                                {lang.t('about.experience.stage')}, Studio Hyperdrive
                            </p>
                        </div>
                    </div>
                    <p className='textExperience'>
                        {lang.t('about.experience.hyperdrive.text.1')}
                        <br />
                        <br />
                        {lang.t('about.experience.hyperdrive.text.2')}
                    </p>
                </div>
            </div>
        </section>
    );
};
