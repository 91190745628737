import { FC } from 'react';

import { motion } from 'framer-motion';
import { HomeProps } from './types/Home.types';

import 'styles/pages/Home.styles.scss';

export const Home: FC<HomeProps> = ({ lang }) => {
    return (
        <motion.section
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: 'easeInOut', duration: 0.75 }}
            className='homePageContainer'>
            <div className='infoContent'>
                <div className='headTitleContainer'>
                    <h1 className='headTitle'>{lang.t('home.headTitle')},</h1>
                    <h1 className='headTitle secondHeadTitle'>
                        {lang.t('home.headTitle.2')}{' '}
                        <span className='gradientHeadTitle'>Hannelore</span>
                    </h1>
                </div>
                <p className='functionsHead'>{lang.t('home.function')}</p>
                <p className='descriptionText'>{lang.t('home.about')}</p>
            </div>
            <div className='imgHeader'>
                <img
                    src={require('../../assets/CV_IMG.png')}
                    loading='lazy'
                    alt='Placeholder image'
                />
            </div>
        </motion.section>
    );
};

// export default box(Home);
