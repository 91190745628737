import { FC } from 'react';

import { HamburgerProps } from './types/Hamburger.types';

export const HamburgerMenu: FC<HamburgerProps> = ({
    showMobileNav,
    handleShowNavbar,
    darkNav,
}) => {
    return (
        <div
            className={showMobileNav === false ? 'hamburger' : 'hamburger open'}
            onClick={handleShowNavbar}>
            <div
                className={
                    darkNav ? 'dark-line-menu half start' : 'line-menu half start'
                }
            />
            <div className={darkNav ? 'dark-line-menu' : 'line-menu'} />
            <div
                className={
                    darkNav ? 'dark-line-menu half end' : 'line-menu half end'
                }
            />
        </div>
    );
};
