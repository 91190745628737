import { FC, useEffect, useState } from 'react';

import { motion } from 'framer-motion';
import { NavLink, useLocation } from 'react-router-dom';

import { DesktopDropdown } from 'shared/dropdown/Dropdown.desktop';
import { NavBarProps } from '../../types/Navigation.types';

import 'styles/pages/Navigation.styles.scss';
import { NavlinkComp } from '../navLinkComp/LinkComp.page';

export const NavigationDesktop: FC<NavBarProps> = ({ lang, routes }) => {
    const location = useLocation();

    const [activeTab, setActiveTab] = useState(location.pathname);
    const [darkNav, setDarkNav] = useState(false);

    useEffect(() => {
        setActiveTab(location.pathname);
        if (location.pathname === '/contact') {
            setDarkNav(true);
        } else {
            setDarkNav(false);
        }
    }, [location]);

    return (
        <motion.nav
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: 'easeInOut', duration: 0.75 }}
            className={darkNav ? 'contactNav desktopNavContainer' : 'desktopNavContainer'}>
            <NavLink to='/' className='imgNav'>
                {darkNav ? (
                    <img
                        loading='lazy'
                        src='/assets/white/Logo_HM_512.png'
                        alt='icon logo'
                    />
                ) : (
                    <img
                        loading='lazy'
                        src='/assets/black/Logo_HM_512.png'
                        alt='icon logo'
                    />
                )}
            </NavLink>
            <ul className='navItems'>
                {routes.map((routeData: any, i: number) => {
                    const { href, label } = routeData;
                    return (
                        <NavlinkComp
                            activeTab={activeTab}
                            darkNav={darkNav}
                            hoverIntensity={1.1}
                            tapIntensity={0.9}
                            lang={lang}
                            pathRoute={href}
                            labelRoute={label}
                            key={i}
                        />
                    );
                })}
                {/* <NavLink to='/about' className='navLink'>
                    <motion.div
                        className={
                            activeTab === '/about'
                                ? 'navItem activeNav'
                                : darkNav
                                  ? 'contactNavText navItem'
                                  : 'navItem'
                        }
                        whileHover={{ scale: hoverIntensity }}
                        whileTap={{ scale: tapIntensity }}
                        transition={{ type: 'spring', stiffness: 400, damping: 10 }}>
                        &#8226; {lang.t('nav.about')}
                    </motion.div>
                </NavLink>
                <NavLink to='/contact' className='navLink'>
                    <motion.div
                        className={
                            activeTab === '/contact'
                                ? ' navItem contactNavText activeNav'
                                : 'navItem'
                        }
                        whileHover={{ scale: hoverIntensity }}
                        whileTap={{ scale: tapIntensity }}
                        transition={{ type: 'spring', stiffness: 400, damping: 10 }}>
                        &#8226; Contact
                    </motion.div>
                </NavLink> */}
                <DesktopDropdown
                    classDropdown={darkNav ? 'darkDropdown' : 'dropdownLang'}
                    classLabelDropdown={
                        darkNav ? 'darkLabelDropdown' : 'labelDropdown'
                    }
                />
            </ul>
        </motion.nav>
    );
};
