import { FC } from 'react';

import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';

import { NavlinkCompProps } from './types/LinkComp.types';

export const NavlinkComp: FC<NavlinkCompProps> = ({
    activeTab,
    darkNav,
    hoverIntensity,
    tapIntensity,
    lang,
    pathRoute,
    labelRoute,
    onClick,
}) => {
    return (
        <NavLink to={pathRoute} className='navLink' onClick={onClick}>
            <motion.div
                className={
                    activeTab === pathRoute
                        ? 'navItem activeNav'
                        : darkNav
                            ? 'contactNavText navItem'
                            : 'navItem'
                }
                whileHover={{ scale: hoverIntensity }}
                whileTap={{ scale: tapIntensity }}
                transition={{ type: 'spring', stiffness: 400, damping: 10 }}>
                &#8226; {lang.t(labelRoute)}
            </motion.div>
        </NavLink>
    );
};
