import React, { useEffect, useState } from 'react';

import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Projects, Home, Contact, About } from 'components';

import { UseScreenSize } from 'hooks';
import { Navigation, Preloader, ProjectCard } from 'shared';

import 'hooks/translation/i18n';

function App() {
    const screenSize = UseScreenSize();
    const location = useLocation();
    const { i18n } = useTranslation();

    const [deviceType, setDeviceType] = useState('none');
    const [allProjects, setAllProjects] = useState<Array<object>>([]);

    useEffect(() => {
        fetch('data/projects.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => response.json())
            .then((myJson) => setAllProjects(myJson));
    }, []);

    useEffect(() => {
        if (screenSize.width < 940) {
            if (screenSize.width < 768) {
                setDeviceType('mobile');
            } else {
                setDeviceType('ipad');
            }
        } else {
            setDeviceType('desktop');
        }
    }, [screenSize, location]);

    return (
        <>
            <AnimatePresence>
                <Preloader />
                <div className='relativeContainer'>
                    <Navigation deviceType={deviceType} lang={i18n} />
                    <Routes location={location} key={location.pathname}>
                        <Route index element={<Home lang={i18n} />} />
                        <Route
                            path='/work'
                            element={
                                <Projects
                                    projectsData={allProjects}
                                    deviceType={deviceType}
                                    lang={i18n}
                                />
                            }
                        />
                        <Route
                            path='/work/:projectTitle'
                            element={<ProjectCard lang={i18n} />}
                        />
                        <Route
                            path='/about'
                            element={<About deviceType={deviceType} lang={i18n} />}
                        />
                        <Route path='/contact' element={<Contact lang={i18n} />} />
                    </Routes>
                </div>
            </AnimatePresence>
        </>
    );
}

export default App;
