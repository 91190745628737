import { FC } from 'react';

import { motion } from 'framer-motion';
import { WorkProps } from './types/Work.types';

import { WorkDesktop } from './desktop/Work.desktop';
import { WorkMobile } from './mobile/Work.mobile';

import 'styles/pages/Work.styles.scss';

export const Projects: FC<WorkProps> = ({ projectsData, deviceType, lang }) => {
    return (
        <motion.section
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: 'easeInOut', duration: 0.75 }}
            className='projectsContainer'>
            {deviceType === 'desktop' ? (
                <WorkDesktop projectsData={projectsData} lang={lang} />
            ) : (
                <WorkMobile projectsData={projectsData} lang={lang} />
            )}
        </motion.section>
    );
};
