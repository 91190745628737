import React from 'react';

export const Project = ({ index, project, setModal }: any) => {
    const year = new Date(project.dateOfRelease).getFullYear();

    return (
        <tr
            onMouseEnter={() => {
                setModal({ active: true, index });
            }}
            onMouseLeave={() => {
                setModal({ active: false, index });
            }}
            className='project'>
            <td className='titleDesktop'>{project.title}</td>
            <td>{project.headcategory}</td>
            <td>{year}</td>
        </tr>
    );
};
