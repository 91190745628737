/* eslint-disable max-len */
import { FC, useEffect, useState } from 'react';

import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';

import { MobileDropdown } from 'shared/dropdown/Dropdown.mobile';

import { SubNavigationProps } from '../types/SubNavigation.types';
import { NavlinkComp } from '../navLinkComp/LinkComp.page';
import { HamburgerMenu } from '../hamburger/Hamburger.page';

export const NavigationMobile: FC<SubNavigationProps> = ({
    lang,
    activeTab,
    darkNav,
    hoverIntensity,
    tapIntensity,
    deviceType,
    routes,
}) => {
    const [showMobileNav, setShowMobileNav] = useState(false);

    const handleShowNavbar = () => {
        setShowMobileNav(!showMobileNav);
    };

    return (
        <motion.nav
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: 'easeInOut', duration: 0.75 }}
            className={darkNav ? 'contactNav fixedNavigation' : 'fixedNavigation'}>
            <div className='navContainer'>
                <NavLink to='/' className='imgNav'>
                    {darkNav ? (
                        <img
                            loading='lazy'
                            src='/assets/white/Logo_HM_512.png'
                            alt='icon logo'
                        />
                    ) : (
                        <img
                            loading='lazy'
                            src='/assets/black/Logo_HM_512.png'
                            alt='icon logo'
                        />
                    )}
                </NavLink>
                <HamburgerMenu
                    showMobileNav={showMobileNav}
                    handleShowNavbar={handleShowNavbar}
                    darkNav={darkNav}
                />
                <ul
                    className={
                        darkNav
                            ? `navItemsMobile darkNavItems  ${showMobileNav && 'active'}`
                            : `navItemsMobile  ${showMobileNav && 'active'}`
                    }>
                    {routes.map((routeData: any, i: number) => {
                        const { href, label } = routeData;
                        return (
                            <NavlinkComp
                                activeTab={activeTab}
                                darkNav={darkNav}
                                hoverIntensity={hoverIntensity}
                                tapIntensity={tapIntensity}
                                lang={lang}
                                pathRoute={href}
                                labelRoute={label}
                                key={i}
                                onClick={handleShowNavbar}
                            />
                        );
                    })}

                    <MobileDropdown
                        onClick={handleShowNavbar}
                        classDropdown={darkNav ? 'darkDropdown' : 'dropdownLang'}
                        classLabelDropdown={
                            darkNav ? 'darkLabelDropdown' : 'labelDropdown'
                        }
                        deviceType={deviceType}
                    />
                </ul>
            </div>
            <div
                className={`greyOverlay ${showMobileNav && 'activeOverlay'}`}
                onClick={handleShowNavbar}
            />
        </motion.nav>
    );
};
