import { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';

import { MobileDropdownProps } from './types/Dropdown.types';

import 'styles/pages/Dropdown.styles.scss';

export const MobileDropdown: FC<MobileDropdownProps> = ({
    classDropdown,
    classLabelDropdown,
    deviceType,
    onClick,
}) => {
    const { i18n } = useTranslation();
    const location = useLocation();

    const [darkNav, setDarkNav] = useState(false);

    const [hoverIntensity, setHoverIntensity] = useState(1.1);
    const [tapIntensity, setTapIntensity] = useState(0.9);

    useEffect(() => {
        if (location.pathname === '/contact') {
            setDarkNav(true);
        } else {
            setDarkNav(false);
        }
    }, [location]);

    useEffect(() => {
        if (deviceType === 'mobile') {
            setHoverIntensity(1.3);
            setTapIntensity(1.1);
        } else if (deviceType === 'ipad') {
            setHoverIntensity(1.2);
            setTapIntensity(1);
        } else {
            setHoverIntensity(1.1);
            setTapIntensity(0.9);
        }
    }, [deviceType]);

    const onClickLanguageChange = (e: any) => {
        const language = e.target.value;
        i18n.changeLanguage(language);
        onClick();
    };

    return (
        <div className='customSelect'>
            <motion.select
                whileHover={{ scale: hoverIntensity }}
                whileTap={{ scale: tapIntensity }}
                transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                className={classDropdown}
                onChange={onClickLanguageChange}
                id='selectLanguage'>
                <option
                    className={
                        darkNav ? 'labelDropdown darkDropdown' : 'labelDropdown'
                    }
                    value='en'>
                    &#8226; EN
                </option>
                <option className={classLabelDropdown} value='nl'>
                    &#8226; NL
                </option>
            </motion.select>
        </div>
    );
};
