import { FC } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { ProjectCardProps } from './types/ProjectCard.types';

import 'styles/pages/ProjectCard.styles.scss';
import { motion } from 'framer-motion';

export const ProjectCard: FC<ProjectCardProps> = ({ lang }) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const project = state.projectData;

    const dayProject = new Date(project.dateOfRelease).getDate();
    const MonthProject = new Date(project.dateOfRelease).getMonth();
    const yearProject = new Date(project.dateOfRelease).getFullYear();
    const dateProject = `${dayProject}/${MonthProject}/${yearProject}`;

    return (
        <motion.section
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: 'easeInOut', duration: 0.75 }}
            className='projectCardContainer'>
            <div className='goBackContainer' onClick={() => navigate(-1)}>
                <i className='arrowBackIcon' />
                <span>{lang.t('project.back')}</span>
            </div>
            <div className='contentCardContainer'>
                <h1 className='titleProjectCard'>{project.title}</h1>
                <div className='infoContainer'>
                    <div className='statsContainer'>
                        <div className='statsItem'>
                            <h3>{lang.t('project.categories')}</h3>
                            <div className='itemStats'>
                                {lang.language === 'en'
                                    ? project.categories_en.map(
                                        (cat: any, i: number) => (
                                            <p key={i}>{cat}</p>
                                        ),
                                    )
                                    : project.categories_nl.map(
                                        (cat: any, i: number) => (
                                            <p key={i}>{cat}</p>
                                        ),
                                    )}
                            </div>
                        </div>
                        <div className='statsItem'>
                            <h3>{lang.t('project.software')}</h3>
                            <div className='itemStats'>
                                {project.type_of_application.map(
                                    (type: any, i: number) => (
                                        <p key={i}>{type}</p>
                                    ),
                                )}
                            </div>
                        </div>
                        <div className='statsItem'>
                            <h3>{lang.t('project.date')}</h3>
                            <p>{dateProject}</p>
                        </div>
                    </div>
                    <div className='descriptionContainer'>
                        {lang.language === 'en'
                            ? project.description_en.map(
                                (description: string, i: number) => (
                                    <p key={i}>
                                        {description} <br />
                                        <br />
                                    </p>
                                ),
                            )
                            : project.description_nl.map(
                                (description: string, i: number) => (
                                    <p key={i}>
                                        {description} <br />
                                        <br />
                                    </p>
                                ),
                            )}
                    </div>
                    <div className='imagesProjectCardContainer'>
                        <div className='imgProjectCard'>
                            <img
                                loading='lazy'
                                src={`/assets/projects/${project.images_showreal.path}`}
                                alt={project.images_showreal.name}
                            />
                        </div>
                        <div className='imgProjectsCardContainer'>
                            {project.images_projects.map((img: any, i: number) => {
                                return (
                                    <div className='imgProjectCard' key={i}>
                                        <img
                                            loading='lazy'
                                            src={`/assets/projects/${img.path}`}
                                            alt={img.name}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </motion.section>
    );
};
