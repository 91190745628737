import { useEffect } from 'react';

import { motion, useAnimate } from 'framer-motion';

import 'styles/pages/Preloader.styles.scss';

export const Preloader = () => {
    const [scope, animate] = useAnimate();

    async function myAnimation() {
        await animate('#imgCon', {
            clipPath: 'polygon(50% 40%, 50% 40%, 50% 60%, 50% 60%)',
            display: 'none',
        });

        await animate(
            '#imgCon',
            {
                clipPath: 'polygon(0% 40%, 100% 40%, 100% 60%, 0% 60%)',
                display: 'flex',
            },
            {
                delay: 0.45,
                duration: 0.4,
                ease: 'easeInOut',
            },
        );

        await animate(
            '#imgCon',
            {
                clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
                display: 'flex',
            },
            {
                delay: 0.2,
                duration: 0.4,
                ease: 'easeInOut',
            },
        );
    }

    useEffect(() => {
        myAnimation();
    }, []);

    const imgChange = {
        hidden: {
            display: 'block',
        },
        show: (i: number) => ({
            display: 'none',
            transition: {
                duration: 0.6,
                delay: i,
                ease: 'easeInOut',
            },
        }),
    };

    const move = {
        hidden: (i: number[]) => ({
            y: '100%',
            x: i[0],
        }),
        moveUp: (i: number[]) => ({
            y: 0,
            transition: {
                delay: i[1],
                duration: 0.5,
                ease: 'easeInOut',
            },
        }),
        moveRight: {
            x: '10px',
            transition: {
                delay: 0.9,
                duration: 0.5,
                ease: 'easeInOut',
            },
        },
        moveLeft: {
            x: '-10px',
            transition: {
                delay: 0.9,
                duration: 0.5,
                ease: 'easeInOut',
            },
        },
    };

    const containerUp = {
        hidden: {
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
        },
        show: {
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
            display: 'none',
            transition: {
                delay: 4,
                duration: 0.6,
                ease: 'easeInOut',
            },
        },
    };

    return (
        <motion.div
            variants={containerUp}
            initial='hidden'
            animate='show'
            className='loaderContainer'
            ref={scope}>
            <motion.section className='loaderSection'>
                <div className='clip'>
                    <motion.h1
                        variants={move}
                        initial='hidden'
                        animate={['moveUp', 'moveLeft']}
                        custom={[0, 0.2]}
                        className='titleText'>
                        CODEBY
                    </motion.h1>
                </div>
                <motion.div id='imgCon' className='spacer'>
                    <motion.img
                        // eslint-disable-next-line max-len
                        src='/assets/projects/sdd/showreal/Hannelore_Maes_SDD_Showreal.jpg'
                        alt='showreal_Silke-De-Decker'
                        loading='lazy'
                        className='imgSpacer'
                        variants={imgChange}
                        initial='hidden'
                        animate='show'
                        custom={1.8}
                    />
                    <motion.img
                        // eslint-disable-next-line max-len
                        src='/assets/projects/brussels_explorer/images/Hannelore_Maes_Brussels_Explorer_Mockup.jpg'
                        alt='showreal_brussels_explorer'
                        loading='lazy'
                        className='imgSpacer'
                        variants={imgChange}
                        initial='hidden'
                        animate='show'
                        custom={2.2}
                    />
                    <motion.img
                        // eslint-disable-next-line max-len
                        src='/assets/projects/rule_of_thirds/showreal/Hannelore_Maes_Rule_Of_Thirds_Showreal.jpg'
                        alt='showreal_rule_of_thirds'
                        loading='lazy'
                        className='imgSpacer'
                        variants={imgChange}
                        initial='hidden'
                        animate='show'
                        custom={2.8}
                    />
                    <motion.img
                        // eslint-disable-next-line max-len
                        src='/assets/projects/datevibe/showreal/Hannelore_Maes_Datevibe_showreal.png'
                        alt='showreal_datevibe'
                        loading='lazy'
                        className='imgSpacer'
                        variants={imgChange}
                        initial='hidden'
                        animate='show'
                        transition={{ delay: 3.4, duration: 0.6, ease: 'easeInOut' }}
                    />
                </motion.div>
                <div className='clip'>
                    <motion.h1
                        variants={move}
                        initial='hidden'
                        animate={['moveUp', 'moveRight']}
                        custom={[0, 0.3]}
                        className='titleText'>
                        Hannelore
                    </motion.h1>
                </div>
            </motion.section>
        </motion.div>
    );
};
