import { FC, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { NavigationDesktop } from './components/responsive/Navigation.desktop';
import { NavigationMobile } from './components/responsive/Navigation.mobile';
import { NavBarProps } from './types/Navigation.types';

import 'styles/pages/Navigation.styles.scss';

export const Navigation: FC<NavBarProps> = ({ deviceType, lang }) => {
    const location = useLocation();

    const [hoverIntensity, setHoverIntensity] = useState(1.1);
    const [tapIntensity, setTapIntensity] = useState(0.9);

    const [activeTab, setActiveTab] = useState(location.pathname);
    const [darkNav, setDarkNav] = useState(false);

    const routes = [
        {
            href: '/work',
            label: 'nav.work',
        },
        {
            href: '/about',
            label: 'nav.about',
        },
        {
            href: '/contact',
            label: 'nav.contact',
        },
    ];

    useEffect(() => {
        setActiveTab(location.pathname);
        if (location.pathname === '/contact') {
            setDarkNav(true);
        } else {
            setDarkNav(false);
        }
    }, [location]);

    useEffect(() => {
        if (deviceType === 'mobile') {
            setHoverIntensity(1.3);
            setTapIntensity(1.1);
        } else if (deviceType === 'ipad') {
            setHoverIntensity(1.2);
            setTapIntensity(1);
        } else {
            setHoverIntensity(1.1);
            setTapIntensity(0.9);
        }
    }, [deviceType]);

    return (
        <>
            {deviceType === 'desktop' ? (
                <NavigationDesktop routes={routes} deviceType={deviceType} lang={lang} />
            ) : (
                <NavigationMobile
                    deviceType={deviceType}
                    lang={lang}
                    activeTab={activeTab}
                    darkNav={darkNav}
                    hoverIntensity={hoverIntensity}
                    tapIntensity={tapIntensity}
                    routes={routes}
                />
            )}
        </>
    );
};
