import { FC, useState } from 'react';

import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { Modal } from './components/Modal';
import { Project } from './components/ProjectCard';
import { WorkProps } from '../types/Work.types';

export const WorkDesktop: FC<WorkProps> = ({ projectsData, lang }: any) => {
    const [modal, setModal] = useState({ active: false, index: 0 });

    const [category, setCategory] = useState(projectsData);
    const [activeFilter, setActiveFilter] = useState('all');

    const [hoverIntensity, setHoverIntensity] = useState(1.1);
    const [tapIntensity, setTapIntensity] = useState(0.9);

    const handleBtns = (e: any) => {
        const word = e.target.value;

        if (word === 'all') {
            setCategory(projectsData);
            setActiveFilter('all');
        } else if (word === 'design') {
            const filtered = projectsData.filter((item: any) =>
                item.headcategory.toLowerCase().includes(word),
            );
            setCategory(filtered);
            setActiveFilter('design');
        } else if (word === 'development') {
            const filtered = projectsData.filter((item: any) =>
                item.headcategory.toLowerCase().includes(word),
            );
            setCategory(filtered);
            setActiveFilter('development');
        }
    };

    return (
        <div className='projectsDesktopContainer'>
            <h1 className='headTitleWorkDesktop'>
                {lang.t('work.headTitle')}{' '}
                <span className='gradientHeadTitle'>
                    {lang.t('work.headTitle.2')}
                </span>
            </h1>
            <div className='filterBtnContainer filterDesktopContainer'>
                <motion.button
                    whileHover={{ scale: hoverIntensity }}
                    whileTap={{ scale: tapIntensity }}
                    transition={{
                        type: 'spring',
                        stiffness: 400,
                        damping: 10,
                    }}
                    value='all'
                    onClick={handleBtns}
                    className={activeFilter === 'all' ? 'activeFilter' : ''}>
                    {lang.t('work.filter.all')}
                </motion.button>
                <motion.button
                    whileHover={{ scale: hoverIntensity }}
                    whileTap={{ scale: tapIntensity }}
                    transition={{
                        type: 'spring',
                        stiffness: 400,
                        damping: 10,
                    }}
                    value='design'
                    onClick={handleBtns}
                    className={activeFilter === 'design' ? 'activeFilter' : ''}>
                    Design
                </motion.button>
                <motion.button
                    whileHover={{ scale: hoverIntensity }}
                    whileTap={{ scale: tapIntensity }}
                    transition={{
                        type: 'spring',
                        stiffness: 400,
                        damping: 10,
                    }}
                    value='development'
                    onClick={handleBtns}
                    className={activeFilter === 'development' ? 'activeFilter' : ''}>
                    Development
                </motion.button>
            </div>
            <div className='projectsMapDesktop'>
                <table>
                    <thead>
                        <tr>
                            <th>{lang.t('work.table.head.project')}</th>
                            <th>{lang.t('work.table.head.category')}</th>
                            <th>{lang.t('work.table.head.year')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {category.length === 0
                            ? projectsData.map((project: any, index: number) => {
                                return (
                                    <Link
                                        className='contentWorkDesktop'
                                        to={`/work/${project.title}`}
                                        state={{ projectData: project }}
                                        key={index}>
                                        <Project
                                            index={index}
                                            project={project}
                                            setModal={setModal}
                                        />
                                    </Link>
                                );
                            })
                            : category.map((project: any, index: number) => {
                                return (
                                    <Link
                                        className='contentWorkDesktop'
                                        to={`/work/${project.title}`}
                                        state={{ projectData: project }}
                                        key={index}>
                                        <Project
                                            index={index}
                                            project={project}
                                            setModal={setModal}
                                        />
                                    </Link>
                                );
                            })}
                    </tbody>
                </table>
            </div>
            {category.length === 0 ? (
                <Modal modal={modal} projects={projectsData} lang={lang} />
            ) : (
                <Modal modal={modal} projects={category} lang={lang} />
            )}
        </div>
    );
};
