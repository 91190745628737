import { FC } from 'react';

import { motion } from 'framer-motion';

import { AboutProps } from './types/About.types';

import 'styles/pages/About.styles.scss';
import { WorkExperience } from './components/Experience.about';
import { References } from './components/References.about';

export const About: FC<AboutProps> = ({ deviceType, lang }) => {
    return (
        <motion.section
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: 'easeInOut', duration: 0.75 }}
            className='aboutSection'>
            <WorkExperience deviceType={deviceType} lang={lang} />
            <References deviceType={deviceType} lang={lang} />
        </motion.section>
    );
};
