import { FC } from 'react';

import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { ContactProps } from './types/Contact.types';

import 'styles/pages/Contact.styles.scss';

export const Contact: FC<ContactProps> = ({ lang }) => {
    return (
        <motion.section
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: 'easeInOut', duration: 0.75 }}
            className='contactSection'>
            <div className='contactInfoContainer'>
                <div className='info'>
                    <h2>{lang.t('contact.info')}</h2>
                    <p>Hannelore Maes</p>
                    <Link to='mailto:hannelore-maes@outlook.be'>
                        Hannelore-Maes@outlook.be
                    </Link>
                </div>
                <div className='getInTouch'>
                    <h2>{lang.t('contact.get.in.touch')}</h2>
                    <Link
                        to='https://linkedin.com/in/hannelore-maes-a308791b9'
                        target='_blank'
                        rel='noreferrer'>
                        LinkedIn
                    </Link>
                    <Link
                        to='https://behance.net/hanneloremaes1'
                        target='_blank'
                        rel='noreferrer'>
                        Behance
                    </Link>
                </div>
            </div>
            <h1 className='footerText'>{lang.t('footer.text')}</h1>
        </motion.section>
    );
};
