import { FC, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import { WorkProps } from '../types/Work.types';

export const WorkMobile: FC<WorkProps> = ({ projectsData, deviceType, lang }) => {
    const [category, setCategory] = useState(projectsData);
    const [activeFilter, setActiveFilter] = useState('all');

    const [hoverIntensity, setHoverIntensity] = useState(1.1);
    const [tapIntensity, setTapIntensity] = useState(0.9);

    useEffect(() => {
        if (deviceType === 'mobile') {
            setHoverIntensity(1.3);
            setTapIntensity(1.1);
        } else if (deviceType === 'ipad') {
            setHoverIntensity(1.2);
            setTapIntensity(1);
        }
    }, [deviceType]);

    const handleBtns = (e: any) => {
        const word = e.target.value;

        if (word === 'all') {
            setCategory(projectsData);
            setActiveFilter('all');
        } else if (word === 'design') {
            const filtered = projectsData.filter((item: any) =>
                item.headcategory.toLowerCase().includes(word),
            );
            setCategory(filtered);
            setActiveFilter('design');
        } else if (word === 'development') {
            const filtered = projectsData.filter((item: any) =>
                item.headcategory.toLowerCase().includes(word),
            );
            setCategory(filtered);
            setActiveFilter('development');
        }
    };

    return (
        <div className='mobileWorkContainer'>
            <h1 className='headTitleWorkMobile'>
                {lang.t('work.headTitle')}{' '}
                <span className='gradientHeadTitle'>
                    {lang.t('work.headTitle.2')}
                </span>
            </h1>
            <div className='filterMobileBtnContainer'>
                <motion.button
                    whileHover={{ scale: hoverIntensity }}
                    whileTap={{ scale: tapIntensity }}
                    transition={{
                        type: 'spring',
                        stiffness: 400,
                        damping: 10,
                    }}
                    value='all'
                    onClick={handleBtns}
                    className={activeFilter === 'all' ? 'activeFilter' : ''}>
                    All
                </motion.button>
                <motion.button
                    whileHover={{ scale: hoverIntensity }}
                    whileTap={{ scale: tapIntensity }}
                    transition={{
                        type: 'spring',
                        stiffness: 400,
                        damping: 10,
                    }}
                    value='design'
                    onClick={handleBtns}
                    className={activeFilter === 'design' ? 'activeFilter' : ''}>
                    Design
                </motion.button>
                <motion.button
                    whileHover={{ scale: hoverIntensity }}
                    whileTap={{ scale: tapIntensity }}
                    transition={{
                        type: 'spring',
                        stiffness: 400,
                        damping: 10,
                    }}
                    value='development'
                    onClick={handleBtns}
                    className={activeFilter === 'development' ? 'activeFilter' : ''}>
                    Development
                </motion.button>
            </div>
            <div className='gridContainer'>
                {category.length === 0
                    ? projectsData.map((project: any, i: number) => {
                        const yearDate = new Date(
                            project.dateOfRelease,
                        ).getFullYear();
                        const imgShowreal = project.images_showreal.path;

                        return (
                            <div className='contentWorkMobile' key={i}>
                                <div className='imgContainerWorkMobile'>
                                    <img
                                        loading='lazy'
                                        className='imgWorkMobile'
                                        src={imgShowreal}
                                        alt='Showreal project'
                                    />
                                </div>
                                <h2 className='titleWorkMobile'>
                                    {project.title}
                                </h2>
                                <div className='underContentWorkMobile'>
                                    <p className='categoryWorkMobile'>
                                        {project.headcategory}
                                    </p>
                                    <p className='yearWorkMobile'>{yearDate}</p>
                                </div>
                            </div>
                        );
                    })
                    : category.map((project: any, i: number) => {
                        const yearDate = new Date(
                            project.dateOfRelease,
                        ).getFullYear();
                        const imgShowreal = project.images_showreal.path;

                        return (
                            <Link
                                to={`/work/${project.title}`}
                                state={{ projectData: project }}
                                className='contentWorkMobile'
                                key={i}>
                                <div className='imgContainerWorkMobile'>
                                    <img
                                        loading='lazy'
                                        className='imgWorkMobile'
                                        src={`/assets/projects/${imgShowreal}`}
                                        alt='Showreal project'
                                    />
                                </div>
                                <h2 className='titleWorkMobile'>
                                    {project.title}
                                </h2>
                                <div className='underContentWorkMobile'>
                                    <p className='categoryWorkMobile'>
                                        {project.headcategory}
                                    </p>
                                    <p className='yearWorkMobile'>{yearDate}</p>
                                </div>
                            </Link>
                        );
                    })}
            </div>
        </div>
    );
};
